<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <tgl-breadcrumbs :items="breadcrumbs" />
    </div>
    <div class="md-layout-item md-medium-size-100 md-size-100">
      <geo-fence-form></geo-fence-form>
    </div>
  </div>
</template>

<script>
import TglBreadcrumbs from "@/components/UI/TglBreadcrumbs";
import GeoFenceForm from "@/components/GeoFence/GeoFenceForm";
export default {
  components: {
    TglBreadcrumbs,
    GeoFenceForm,
  },
  computed: {
    breadcrumbs() {
      if (this.$route.params.dashboard) {
        return [
          {
            label: "Geocerca",
            disabled: false,
            to: { name: "workspace", params: { tab: 1 } },
          },
          {
            label: `Editando geocerca ${this.$route.params.geofence}`,
            disabled: true,
            to: { name: "workspace", params: { tab: 1 } },
          },
        ];
      } else {
        return [
          {
            label: "Geocerca",
            disabled: false,
            to: { name: "workspace", params: { tab: 1 } },
          },
          {
            label: "Crear",
            disabled: true,
            to: { name: "workspace", params: { tab: 1 } },
          },
        ];
      }
    },
  },
};
</script>